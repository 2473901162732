// Typography

@font-face {
    font-family: 'Roboto-Regular';
    src: local( 'Roboto' ),
    url('/frontend/fonts/Roboto-Regular.woff2') format('woff2'),
    url('/frontend/fonts/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: local( 'Roboto' ),
    url('/frontend/fonts/Roboto-Medium.woff2') format('woff2'),
    url('/frontend/fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: local( 'Roboto' ),
    url('/frontend/fonts/Roboto-Bold.woff2') format('woff2'),
    url('/frontend/fonts/Roboto-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
}

// Social colors
@each $color, $value in $social-colors {
  .text-#{$color} {
    color: $value;
  }
}
