.dataTables_wrapper {
  &.dt-bootstrap4 {
    .dataTables_length {
      @media(max-width: 767px) {
        text-align: left;
      }
      select {
        @extend .w-100;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .dataTables_filter {
      @media(max-width: 767px) {
        text-align: left;
      }
    }
  }
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
  // RTL fix
  padding-left: 0;
  padding-right: 12px;
}

div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
  // RTL fix
  padding-right: 0;
  padding-left: 12px;
}