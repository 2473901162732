/*
* NobleUI - Laravel Admin Dashboard Template v2.1
* Copyright © 2022 NobleUI
* Licensed under ThemeForest License
*/

// Custom variables
@import './admin/variables';


// Bootstrap stylesheets
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";

// Bootstrap layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";

// Bootstrap helpers
@import "~bootstrap/scss/helpers";

// Bootstrap utilities
@import './admin/utilities';
@import "~bootstrap/scss/utilities/api";


// Custom mixins
@import './admin/mixins/animation';
@import './admin/mixins/buttons';
@import './admin/mixins/misc';
@import './admin/mixins/width';

// Core styles
@import './admin/background';
@import './admin/reset';
@import './admin/functions';
@import './admin/misc';
@import './admin/helpers';
@import './admin/typography';
@import './admin/demo';
@import './admin/spinner';

// Layout
@import './admin/vertical-wrapper';
@import './admin/navbar';
@import './admin/sidebar';
@import './admin/layouts';

// Custom components
@import "./admin/components/badges";
@import "./admin/components/bootstrap-alert";
@import "./admin/components/breadcrumbs";
@import "./admin/components/buttons";
@import "./admin/components/cards";
@import "./admin/components/dashboard";
@import "./admin/components/dropdown";
@import "./admin/components/forms";
@import "./admin/components/icons";
@import "./admin/components/nav";
@import "./admin/components/pagination";
@import "./admin/components/tables";
@import "./admin/components/timeline";
@import "./admin/components/chat";
@import "./admin/components/auth";

// Email app
@import './admin/components/email/inbox';

// 3rd-Party plugin overrides
@import "./admin/components/plugin-overrides/ace";
@import "./admin/components/plugin-overrides/apex-charts";
@import "./admin/components/plugin-overrides/bootstrap-datepicker";
@import "./admin/components/plugin-overrides/data-tables";
@import "./admin/components/plugin-overrides/dropify";
@import "./admin/components/plugin-overrides/dropzone";
@import "./admin/components/plugin-overrides/full-calendar";
@import "./admin/components/plugin-overrides/jquery-flot";
@import "./admin/components/plugin-overrides/morrisjs";
@import "./admin/components/plugin-overrides/peity";
@import "./admin/components/plugin-overrides/perfect-scrollbar";
@import "./admin/components/plugin-overrides/sweet-alert";
@import "./admin/components/plugin-overrides/select2";
@import "./admin/components/plugin-overrides/simplemde";
@import "./admin/components/plugin-overrides/tags-input";
@import "./admin/components/plugin-overrides/tempusdominus-bootstrap-4";
@import "./admin/components/plugin-overrides/tinymce";
@import "./admin/components/plugin-overrides/typeahead";
@import "./admin/components/plugin-overrides/wizard";


@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.css";
@import '~datatables.net-select-bs4/css/select.bootstrap4.css';
@import '~datatables.net-searchpanes-bs4/css/searchPanes.bootstrap4.css';
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";


@import 'frontend/components/micro_modal';
@import 'tippy.js/dist/tippy.css';
@import "select2/dist/css/select2.min.css";
@import "dropzone/dist/dropzone.css";
