// Tags 

div.tagsinput {
  padding: 6px 6px 1px;
  border-color: $input-border-color;
  border-radius: $input-border-radius;
  background: $input-bg;
  color: $text-muted;
  span.tag {
    background: $primary;
    border: 0;
    color: $white;
    padding: 3px 7px;
    font-family: inherit;
    border-radius: .15rem;
    margin-bottom: 4px;
    float: left;
    // RTL fix
    margin-right: 0;
    margin-left: 5px;
    a {
      font-size: 13px;
      font-weight: 500;
      color: $white;
      opacity: .5;
    }
  }
  #tags_addTag {
    float: left;
    // RTL fix
    margin-left: 5px;
  }
  input {
    margin: 0;
    padding: 1px;
    border-radius: $border-radius;
    @extend .text-muted;
  }
}